import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';
import { capitalizeFirstLetter } from '@utils/helpers';

import Text from '@components/text';
import { Link } from 'gatsby';
import BlockSanity from '@components/block-sanity';
import Share from '@components/post/share';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 mb-70 px-12
    md:w-535 md:mb-50
    lg:px-0
  `}
`;

const TagsWrapper = tw.div`flex flex-wrap mt-4 mb-16 -mx-5 md:mb-14`;

const LinkTag = tw(Link)`mx-5 hover:opacity-80 transition-opacity duration-300`;

const Desc = styled(Text)`
  ${tw`mt-15 mb-15 md:mb-40`}
  
  p {
    line-height: 1.36 !important;
    ${tw`text-22!`}
  }
`;

const Content = ({ post, locale, href }) => {
  const pathPart = locale === 'en' ? '/en/tag/' : '/tag/';
  const pathTo = (path) => `${pathPart}${path}`;

  return (
    <Wrapper>
      <Share href={href} />
      <TagsWrapper>
        {post.post_tags.map((tag) => (
          <LinkTag key={tag.name_en} to={pathTo(tag[`slug_${locale}`].current)}>
            <Text color="blueDark" fontSizes={14} letterSpacing={9}>
              {tag[`name_${locale}`]}
            </Text>
          </LinkTag>
        ))}
      </TagsWrapper>
      <Text fontSizes={[36, 42]} lineHeight={[1.33, 1.14]} letterSpacing={[-45, -53]}>
        {post[`title_${locale}`]}
      </Text>
      <Desc fontSizes={22} lineHeight={1.36}>
        <BlockSanity content={post[`_rawPostPreview${capitalizeFirstLetter(locale)}`]} noSpacing />
      </Desc>
      <Text fontSizes={22} fontWeight={300} lineHeight={1.45}>
        {/* eslint-disable-next-line no-underscore-dangle */}
        <BlockSanity content={post._rawPost} blog />
      </Text>
    </Wrapper>
  );
};

Content.propTypes = {
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any,
    ]),
  ).isRequired,
  locale: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default Content;
