import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import IconFacebook from '@images/share-facebook.svg';
import IconLinkedin from '@images/share-linkedin.svg';
import IconCopy from '@images/share-copy.svg';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Wrapper = styled.div`
  right: -78px;
  ${tw`absolute hidden md:flex md:flex-col md:items-center`}
`;

const Item = tw.div`flex justify-center items-center w-full mb-18 cursor-pointer`;

const ItemCopy = tw(CopyToClipboard)`mb-18 cursor-pointer`;

const Tooltip = styled(ReactTooltip)`
  ${tw`ml-10! p-0! text-primary! bg-transparent! opacity-90`}
  
  ::before,
  ::after {
    content: none !important;
  }
`;

const Share = ({ href }) => {
  const handleClick = (type) => {
    const hrefs = {
      fb: `https://www.facebook.com/sharer.php?u=${href}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${href}`,
    };

    return window.open(hrefs[type], '_blank');
  };

  return (
    <Wrapper>
      <Item data-tip data-for="facebook" onClick={() => handleClick('fb')}>
        <IconFacebook />
      </Item>
      <Tooltip id="facebook" place="right">
        <FormattedMessage id="blog.share" />
      </Tooltip>
      <Item data-tip data-for="linkedin" onClick={() => handleClick('linkedin')}>
        <IconLinkedin />
      </Item>
      <Tooltip id="linkedin" place="right">
        <FormattedMessage id="blog.share" />
      </Tooltip>
      <ItemCopy data-tip data-for="copy" text={href}>
        <IconCopy />
      </ItemCopy>
      <Tooltip id="copy" place="right">
        <FormattedMessage id="blog.copy" />
      </Tooltip>
    </Wrapper>
  );
};

Share.propTypes = {
  href: PropTypes.string.isRequired,
};

export default Share;
