import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import ArrowDefault from '@images/arrow-blog.svg';
import Text from '@components/text';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    flex justify-between w-320 mb-15 px-10
    md:w-535
    lg:w-730 lg:mb-0 lg:px-0
  `}
`;

const LinkStyled = tw(Link)`flex items-center`;

const Arrow = styled(ArrowDefault)`
  transform: scaleX(${({ left }) => (left ? -1 : 1)});
  ${({ left }) => (left ? tw`mr-10` : tw`ml-10`)}
`;

const Nav = ({ next, prev, locale }) => {
  const isEng = locale === 'en';
  const pathBuilder = (slug) => {
    const langPart = isEng ? '/en/post/' : '/post/';
    return `${langPart}${slug}`;
  };

  return (
    <Wrapper>
      <div>
        {prev && (
          <LinkStyled to={pathBuilder(prev[`slug_${locale}`].current)}>
            <Arrow left />
            <Text color="greyFont" fontSizes={16} lineHeight={1.5} letterSpacing={10}>
              <FormattedMessage id="blog.prev post" />
            </Text>
          </LinkStyled>
        )}
      </div>
      <div>
        {next && (
          <LinkStyled to={pathBuilder(next[`slug_${locale}`].current)}>
            <Text color="greyFont" fontSizes={16} lineHeight={1.5} letterSpacing={10}>
              <FormattedMessage id="blog.next post" />
            </Text>
            <Arrow />
          </LinkStyled>
        )}
      </div>
    </Wrapper>
  );
};

Nav.defaultProps = {
  next: null,
  prev: null,
};

Nav.propTypes = {
  next: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.string,
    ),
  ),
  prev: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.string,
    ),
  ),
  locale: PropTypes.string.isRequired,
};

export default Nav;
