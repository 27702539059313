import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '@layouts/';
import SEO from '@components/seo.jsx';
import Image from '@components/post/image';
import Content from '@components/post/content';
import Author from '@components/post/author';
import Blog from '@components/homepage/blog';
import Nav from '@components/post/nav';

const Post = ({ location, data }) => {
  const intl = useIntl();

  return (
    <Layout
      langInfo={intl.locale === 'en'}
      switchPath={[
        `/post/${data.current.slug_pl.current}`,
        `/en/post/${data.current.slug_en.current}`,
      ]}
    >
      <SEO
        lang={intl.locale}
        title={data.current[`title_${intl.locale}`]}
        description={intl.formatMessage({ id: 'meta tags.description.blog' })}
        img={data.current.img.asset.fixed.src}
        isImgSanity
      />
      <Image image={data.current.img.asset.fluid} />
      <Content post={data.current} locale={intl.locale} href={location.href || ''} />
      <Author author={data.current.author} locale={intl.locale} />
      <Nav next={data.next} prev={data.prev} locale={intl.locale} />
      <Blog id={data.current.id} />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $idNext: String, $idPrev: String) {
    next: sanityPost(id: {eq: $idNext}) {
      slug_pl {
        current
      }
      slug_en {
        current
      }
    }
    prev: sanityPost(id: {eq: $idPrev}) {
      slug_pl {
        current
      }
      slug_en {
        current
      }
    }
    current: sanityPost(id: {eq: $id}) {
      id
      title_en
      title_pl
      slug_pl {
        current
      }
      slug_en {
        current
      }
      post_tags {
        name_pl
        name_en
        slug_pl {
          current
        }
        slug_en {
          current
        }
      }
      _rawPost
      _rawPostPreviewPl
      _rawPostPreviewEn
      author {
        desc_en
        desc_pl
        name
        position
        img {
          asset {
            fluid(maxWidth: 120) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      img {
        asset {
          fluid(maxWidth: 1440) {
            ...GatsbySanityImageFluid
          }
          fixed(width: 1200) {
            src
          }
        }
      }
    }
  }
`;

Post.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  location: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
    ]),
  ).isRequired,
};

export default Post;
