import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

const StyledImg = styled(Img)`
  ${tw`
    w-320 mt-16 mx-auto mb-36 bg-blue
    md:mt-8 md:mb-72
  `}
  
  @media (min-width: 768px) {
    width: 768px;
  }
  
  @media (min-width: 1024px) {
    width: auto;
    max-width: 1440px;
  }
`;

const Image = ({ image }) => <StyledImg fluid={image} />;

Image.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ).isRequired,
};

export default Image;
